
import { onMounted } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import HeaderMain from '@/components/shared/HeaderMain.vue';

export default {
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    HeaderMain,
  },
  setup() {
    onMounted(async () => {
      if (!(window as any).twitterInjected) {
        const scriptTwitter = document.createElement('script');
        scriptTwitter.setAttribute(
          'src',
          'https://platform.twitter.com/widgets.js'
        );
        document.head.appendChild(scriptTwitter);
        (window as any).twitterInjected = true;
      } else {
        (window as any).twttr.widgets.load();
      }
    });

    return {
      twitter:
        // eslint-disable-next-line no-useless-escape
        '<a class="twitter-timeline" href="https://twitter.com/Bizvento/lists/1458868990441361416?ref_src=twsrc%5Etfw"></a>',
      arrowBackOutline,
    };
  },
};
