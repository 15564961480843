import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMain = _resolveComponent("HeaderMain")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderMain, {
        "page-title": "Twitter",
        "default-href": "/home",
        "icon-filter": false
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            id: "tt",
            innerHTML: $setup.twitter
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}